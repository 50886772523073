<template>
	<div class="container">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix"><span>通用设置</span></div>
				<el-form-item label="商城名称：" :inline-message="true" prop="MallName">
					<el-input v-model="ruleForm.MallName" placeholder="最多输入12个字" maxlength="12"></el-input>
				</el-form-item>

				<el-form-item label="联系电话：">
					<el-input v-model="ruleForm.AreaCode" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"
					 placeholder="区号" style="width: 100px;" maxlength="5"></el-input>
					<span style="margin: 0 10px;">--</span>
					<el-form-item style="display: inline-block;" :inline-message="true" prop="ContactPhone">
						<el-input v-model="ruleForm.ContactPhone" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"
						 placeholder="请输入联系电话，便于客户售后联系" maxlength="11"></el-input>
					</el-form-item>
				</el-form-item>

				<el-form-item label="商城LOGO：" prop="MallLogoUrl">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
					 list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<img v-if="ruleForm.MallLogoUrl" :src="imgUrl + ruleForm.MallLogoUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>

				<!-- prop="mallFooterLogoUrl" -->
				<el-form-item label="商城底部LOGO：" style="margin-top: 35px;">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleFooterAvatarSuccess"
					 list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<img v-if="ruleForm.MallButtomLogoUrl" :src="imgUrl + ruleForm.MallButtomLogoUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="商城风格：" prop="MainColor">
					<el-color-picker v-model="ruleForm.MainColor"></el-color-picker>
				</el-form-item>
			</el-card>

			<el-card class="box-card" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>企业微信欢迎语</span></div>
				<div class="content">
					<el-form-item label="欢迎语：">
						<el-input v-model="ruleForm.Welcom" type="textarea" :rows="4" placeholder="在客户与员工好友添加成功后，自动回复的内容，最多500字"
						 maxlength="500"></el-input>
					</el-form-item>
				</div>
			</el-card>

			<el-card class="box-card payMoneys-with" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>交易设置</span></div>
				<div class="content">
					<el-form-item label="待付款订单自动取消时间：" label-width="300" :required="true">
						<div class="filter-container">
							<div class="filter-item">订单拍下后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0" prop="WaitPayOrderCancelTimeShow">
									<el-input v-model="ruleForm.WaitPayOrderCancelTimeShow" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
									 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
									 style="width:120px;margin:0 10px;">
									</el-input>
									<span>分钟内未付款，订单自动取消</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="发货后,自动确认收货时间：" label-width="300" :required="true">
						<div class="filter-container">
							<div class="filter-item">订单发货后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0" prop="AutomaticReceivingTimeToSecondShow">
									<el-input v-model="ruleForm.AutomaticReceivingTimeToSecondShow" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
									 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
									 style="width:120px;margin:0 10px;">
									</el-input>
									<span>天，订单自动确认收货</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="自动同意退货/退款申请：" label-width="300" :required="true">
						<el-radio v-model="ruleForm.IsOpenAutoConsentSupportRequest" :label="1">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenAutoConsentSupportRequest" :label="0">关闭</el-radio>
						<div class="filter-container" v-if="ruleForm.IsOpenAutoConsentSupportRequest==1" style="margin-left:178px">
							<div class="filter-item">买家提交申请后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0" :prop="ruleForm.IsOpenAutoConsentSupportRequest==1?'AutoConsentSupportRequestTimeShow':'nocheck'"
								 :rules='ruleForm.IsOpenAutoConsentSupportRequest==1?rules.AutoConsentSupportRequestTimeShow:rules.nocheck'>

									<el-input v-model="ruleForm.AutoConsentSupportRequestTimeShow" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
									 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
									 style="width:120px;margin:0 10px;">
									</el-input>
									<span>天商家未处理，自动同意退货/退款申请</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="买家退货寄回地址：" label-width="300" required></el-form-item>
					<div style="margin:-57px 0 0 145px">
						<el-form-item label="收货人姓名：" :inline-message="true" prop="ReceiveName">
							<el-input v-model="ruleForm.ReceiveName" placeholder="最多输入15个字" maxlength="15"></el-input>
						</el-form-item>

						<el-form-item label="收货人手机号：" :inline-message="true" prop="ReceivePhone">
							<el-input v-model="ruleForm.ReceivePhone" maxlength="11"></el-input>
						</el-form-item>

						<el-form-item label="收货地址：" required="">
							<el-form-item :inline-message="true" prop="AddressArr">
								<el-cascader v-model="ruleForm.AddressArr" separator="-" :props="props" :options="dataList" clearable @change="handleAddressChange"></el-cascader>
							</el-form-item>
							<el-form-item :inline-message="true" prop="ReceiveAddress">
								<el-input style="margin-top:10px;" type="textarea" :rows="4" v-model="ruleForm.ReceiveAddress" maxlength="100"></el-input>
							</el-form-item>
						</el-form-item>
					</div>

					<el-form-item label="买家寄回退货商品后,商家自动确认收货时间：" label-width="300" prop="IsOpenMallAutomaticReceiving">
						<el-radio v-model="ruleForm.IsOpenMallAutomaticReceiving" :label="1">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenMallAutomaticReceiving" :label="0">关闭</el-radio>
						<div v-if="ruleForm.IsOpenMallAutomaticReceiving ==1" class="filter-container" style="margin-left:305px">
							<div class="filter-item">买家寄回后</div>
							<div class="filter-item">
								<el-form-item label-width="0" :prop="ruleForm.IsOpenMallAutomaticReceiving ==1?'MallAutomaticReceivingTimeShow':'nocheck'"
								 :rules='ruleForm.IsOpenMallAutomaticReceiving ==1?rules.MallAutomaticReceivingTimeShow:rules.nocheck'>
									<el-input v-model="ruleForm.MallAutomaticReceivingTimeShow" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
									 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
									 style="width:120px;margin:0 10px;">
									</el-input>
									<span>天，商家自动确认收货并且自动退款</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>
				</div>
			</el-card>

			<el-card class="box-card" style="margin: 10px 0;">
				<div slot="header" class="clearfix"><span>支付设置</span></div>
				<el-form-item label="支付方式：" v-if="ruleForm.IsOpen498Pay">
					<el-radio v-model="ruleForm.PayBy" :label="1" @change='handleChangePayBy'>微信支付</el-radio>
					<el-radio v-model="ruleForm.PayBy" :label="5" @change='handleChangePayBy'>第三方支付</el-radio>
				</el-form-item>

				<div v-if='ruleForm.PayBy==1'>
					<el-form-item label="微信支付商户号：">
						<el-input v-model="ruleForm.WxPayMallNo" placeholder="请输入与小程序绑定的微信支付商户号" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
						 style="width:300px;">
						</el-input>
					</el-form-item>

					<el-form-item label="微信退款证书：">
						<div v-if="ruleForm.WxRefundCertImgUrl">退款证书已上传</div>
						<el-upload class="upload-demo" :action="upLoadFile" :show-file-list="false" :on-success="handleAvatarSuccessBook"
						 style="display: inline-block;marin-left:10px;">
							<!-- 如果没有退款证书 -->
							<el-button size="small" type="primary" v-if="!ruleForm.WxRefundCertImgUrl" plain>上传退款证书</el-button>
							<!-- 如果有退款证书 -->
							<el-button size="small" type="primary" v-if="ruleForm.WxRefundCertImgUrl" plain>更换退款证书</el-button>
						</el-upload>

					</el-form-item>
				</div>
				<div v-else>
					<el-form-item label="支付商户号：" v-if='ruleForm.PayBy==5'>
						<el-input v-model="ruleForm.Pay498MchId" placeholder="请输入第三方支付商户号" maxlength="500"></el-input>
					</el-form-item>

					<el-form-item label="支付秘钥：" v-if='ruleForm.PayBy==5'>
						<el-input v-model="ruleForm.Pay498PublicKey" placeholder="请输入第三方支付秘钥" maxlength="500"></el-input>
					</el-form-item>
				</div>

			</el-card>
			<!-- <el-card class="box-card payMoneys-with" style="margin: 10px 0;">
				<div slot="header" class="clearfix"><span></span></div>

				
				
			</el-card> -->

			<el-card class="box-card payMoneys-with">
				<div slot="header" class="clearfix"><span>商详页设置</span></div>

				<el-form-item label="商品详情页，推手开通入口：" label-width="300">
					<el-radio v-model="ruleForm.IsOpenDistributorToWapProductDetail" :label="1">开启</el-radio>
					<el-radio v-model="ruleForm.IsOpenDistributorToWapProductDetail" :label="0">关闭</el-radio>
					<div style="font-size: 14px;color:#999;margin-left:194px">开启后，将会在商品详情页显示推手开通入口，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(1)">查看示例</span>
					</div>
				</el-form-item>

				<el-form-item label="商品详情页，成交记录展示：" label-width="300">
					<el-radio v-model="ruleForm.IsOpenRecordToWapProductDetail" :label="1">开启</el-radio>
					<el-radio v-model="ruleForm.IsOpenRecordToWapProductDetail" :label="0">关闭</el-radio>
					<div style="font-size: 14px;color:#999;;margin-left:194px">开启后，将会在商品详情页显示成交记录，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(2)">查看示例</span>
					</div>
				</el-form-item>

				<el-form-item label="商品详情页，公共图配置：" label-width="300">
					<el-checkbox v-model="ruleForm.IsOpenProductShufflingPublicImg" @change="handleCheckShufflingPublic">商品轮播公共图</el-checkbox>
					<div style="margin-left:180px" v-if='ruleForm.IsOpenProductShufflingPublicImg'>
						<el-radio v-model="ruleForm.PublicImgLocation" :label="0">展示在第一张</el-radio>
						<el-radio v-model="ruleForm.PublicImgLocation" :label="1">展示在最后一张</el-radio>
						<br>
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleGoodsCarouselSuccess"
						 list-type="picture-card" style="display: inline-block;">
							<img v-if="ruleForm.ProductShufflingPublicImgUrl" :src="imgUrl + ruleForm.ProductShufflingPublicImgUrl" class="upload-avatar" />
							<i v-else class="el-icon-plus"></i>
						</el-upload>
					</div>
					<br>
					<el-checkbox style="margin-left:180px" v-model="ruleForm.IsOpenProductDetailPublicImg" @change="handleCheckDetailPublic">商品详情图公共图</el-checkbox>
					<div style="font-size: 14px;color:#999;margin-left:180px">上传后，会显示在商品详情图最后一张</div>
					<el-upload v-if='ruleForm.IsOpenProductDetailPublicImg' class="avatar-uploader" :action="imgApi" :show-file-list="false"
					 :on-success="handleGoodsDetaileSuccess" list-type="picture-card" style="display: inline-block;margin-left:180px">
						<img v-if="ruleForm.ProductDetailPublicImgUrl" :src="imgUrl + ruleForm.ProductDetailPublicImgUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="商详页库存显示：" label-width="300" :required="true">
					<!-- <div class="filter-item"><span style="color:red;margin-right: 4px;font-size: 12px;">*</span>商详页库存显示：</div> -->
					<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="0">始终显示</el-radio>
					<div style="margin-left:130px">
						<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="1">始终不显示</el-radio></br>
						<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="2">
							<div class="filter-container" style="display: inline-block;">
								<div class="filter-item">库存小于(含)</div>
								<div class="filter-item">
									<el-form-item label="" label-width="0" :prop="ruleForm.WapProductDetailStockDisplayType==2?'WapProductDetailStockDisplayMax':'nocheck'"
									 :rules='ruleForm.WapProductDetailStockDisplayType==2?rules.WapProductDetailStockDisplayMax:rules.nocheck'>
										<el-input v-model="ruleForm.WapProductDetailStockDisplayMax" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
										 style="width:120px;margin:0 10px;">
										</el-input>
										件时显示
									</el-form-item>
								</div>
							</div>
						</el-radio>
					</div>
				</el-form-item>

				<el-form-item label="商详页店铺信息显示：" label-width="300">
					<el-radio v-model="ruleForm.WapProductDetailShopInfoDisplayType" :label="1">显示商城信息</el-radio>
					<el-radio v-model="ruleForm.WapProductDetailShopInfoDisplayType" :label="0">显示门店信息</el-radio>
				</el-form-item>
			</el-card>

			<el-card class="box-card payMoneys-with" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>提现规则</span></div>
				<div class="content">
					<div class="filter-container">
						<div class="filter-item"><span style="color:red;margin-right: 4px;font-size: 12px;">*</span>提现账户类型：</div>
						<div class="filter-item">
							<el-form-item label="" label-width='0' style="margin-top:20px" :prop="(ruleForm.IsOpenWithdrawalsToAlipay||ruleForm.IsOpenWithdrawalsToBankCard)?'nocheck':'IsOpenWithdrawalsToAlipay'">
								<el-checkbox v-model="ruleForm.IsOpenWithdrawalsToAlipay" @change="isShowTex=true">提现到支付宝</el-checkbox>
								<el-checkbox v-model="ruleForm.IsOpenWithdrawalsToBankCard" @change="isShowTex=true">提现到银行卡</el-checkbox>
							</el-form-item>
						</div>
					</div>

					<el-form-item label="申请提现时间：" :required="true">
						<el-radio v-model="ruleForm.WithdrawalsDateIsLimit" :label="0">不限</el-radio>
						<div class="filter-container">
							<el-radio v-model="ruleForm.WithdrawalsDateIsLimit" :label="1">

								<div class="filter-item">每月</div>
								<div class="filter-item">
									<el-form-item label="" label-width='0' :prop="ruleForm.WithdrawalsDateIsLimit==1?'WithdrawalsStartDateEveryMonth':'nocheck'"
									 :rules='ruleForm.WithdrawalsDateIsLimit==1?rules.WithdrawalsStartDateEveryMonth:rules.nocheck'>
										<el-input v-model="ruleForm.WithdrawalsStartDateEveryMonth" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
										 style="width:120px;margin:0 10px;">
										</el-input>
									</el-form-item>
								</div>
								<div class="filter-item">
									号至
								</div>
								<div class="filter-item">
									<el-form-item label="" label-width='0' :prop="ruleForm.WithdrawalsDateIsLimit==1?'WithdrawalsEndDateEveryMonth':'nocheck'"
									 :rules='ruleForm.WithdrawalsDateIsLimit==1?rules.WithdrawalsEndDateEveryMonth:rules.nocheck'>
										<el-input v-model="ruleForm.WithdrawalsEndDateEveryMonth" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
										 style="width:120px;margin:0 10px;">
										</el-input>号
									</el-form-item>
								</div>

							</el-radio>
						</div>
					</el-form-item>

					<el-form-item label="起提金额：" prop="WithdrawalsMinMoney">
						<el-input v-model="ruleForm.WithdrawalsMinMoney" style="width:120px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
						元起提
					</el-form-item>



				</div>
			</el-card>



			<el-card class="box-card other-card" style="margin:10px 0 120px 0">
				<div slot="header" class="clearfix"><span>其他设置</span></div>

				<el-form-item label="商城首页与自定义页面,分享图标：" prop="ShareIconImgUrl">
					<div @mouseover="isShowmasking=true" @mouseleave="isShowmasking=false" style="width:150px;height:150px;border:1px solid #fff">
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="shareIconSuccess"
						 list-type="picture-card" style="display: inline-block;marin-left:10px;" :before-upload="beforeAvatarUploadshare">
							<div class="headIconImg">
								<img v-if="ruleForm.ShareIconImgUrl" :src="imgUrl + ruleForm.ShareIconImgUrl" class="upload-avatar" />
								<!-- 上面的蒙版 -->
								<div class="iconImgmasking" v-if="isShowmasking"></div>
								<div class="iconimgBox" v-if="isShowmasking">更改图片</div>
							</div>
							<i v-if="!ruleForm.ShareIconImgUrl" class="el-icon-plus"></i>
						</el-upload>
					</div>

				</el-form-item>

				<el-form-item label="已售罄商品：">
					<el-radio v-model="ruleForm.IsDisplaySoldOut" :label="true">展示</el-radio>
					<el-radio v-model="ruleForm.IsDisplaySoldOut" :label="false">不展示</el-radio>
					<div style="font-size: 14px;color:#999;">展示后，售罄的商品会在商城内展示，并显示“已售罄”标记</div>
				</el-form-item>

				<el-form-item label="推手自购商品，直接抵扣收益：">
					<el-radio v-model="ruleForm.IsOpenDistributorCommissionDeduct" :label="true">开启</el-radio>
					<el-radio v-model="ruleForm.IsOpenDistributorCommissionDeduct" :label="false">关闭</el-radio>
					<div style="font-size: 14px;color:#999;">开启后，推手自己在商城内购买商品，计算最终支付金额时，将自动扣减商品相关收益</div>
				</el-form-item>

				<el-form-item label="推手自动降级：">
					<el-radio v-model="ruleForm.IsAutoDistributorDemotion" :label="true">开启</el-radio>
					<el-radio v-model="ruleForm.IsAutoDistributorDemotion" :label="false">关闭</el-radio>
					<div style="font-size: 14px;color:#999;">开启后，推手若未达到当前级别的晋升门槛，将自动降级</div>
				</el-form-item>

				<el-form-item label="推手须知与提现协议：">
					<div style="font-size: 14px;color:#999;">你可以在这里编辑提现相关注意事项等，配置后将会显示在提现账号绑定页，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(3)">查看示例</span>
					</div>
					<div>协议标题：
						<el-input v-model="ruleForm.UserAgreementTitle" placeholder="最多输入20个字" maxlength='20' />
					</div>
					<div style='display:flex;margin-bottom:150px;'>协议内容：
						<quill-editor ref="myTextEditor" v-model="ruleForm.UserAgreementContent" :options="quillOption" style="margin-top:10px;margin-left:5px;width:750px;min-height: 200px;max-height:720px;"></quill-editor>
					</div>
				</el-form-item>

			</el-card>


		</el-form>
		<el-card class="box-card footer">
			<el-button style="width:240px;position: relative;bottom: 20px;right: 190px;" type="primary" @click="saveSupiler('ruleForm')">保存</el-button>
		</el-card>

		<!-- 查看示例弹框 -->
		<el-dialog :visible.sync="lookVisable" width="570px" title="查看示例" :show-close="false">
			<img :src="lookPicture" alt="" style="width: 540px;">
		</el-dialog>


	</div>
</template>

<script>
	import axios from 'axios';
	import config from '@/config/index';
	import ImageDrag from '@/components/uploadImginbatch';
	import quillConfig from '@/components/quill-config.js'
	import {
		savemallbaseinfo,
		mallbaseinfo
	} from '@/api/wyUsedInterface.js';
	export default {
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var OrderAutoCancelToMinutesCheck = (rule, value, callback) => {

				if (value > 1440 || value < 20) {
					return callback(new Error('时间请设置在20分钟至1440分钟之间'));
				} else {
					return callback();
				}
			};
			var receivetimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 1) {
					return callback(new Error('时间请设置在1天至31天之间'));
				} else {
					return callback();
				}
			};
			var agreenTimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 7) {
					return callback(new Error('时间请设置在7天至31天之间'));
				} else {
					return callback();
				}
			};
			var aciveTimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 7) {
					return callback(new Error('时间请设置在7天至31天之间'));
				} else {
					return callback();
				}
			};
			var stockNumberCheck = (rule, value, callback) => {
				if (value <= 0 || (!value)) {
					return callback(new Error('库存须大于0'));
				} else if (value > 1000) {
					return callback(new Error('库存须不高于1000'));
				} else {
					return callback();
				}
			};
			var withdrawPayCheck = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('至少选择一种账户类型'));
				} else {
					return callback();
				}

			};
			var minTimesCheck = (rule, value, callback) => {

				if (value > 28 || value < 1) {
					return callback(new Error('时间须在1号至28号之间'));
				} else if (value > this.ruleForm.WithdrawalsEndDateEveryMonth) {
					return callback(new Error('开始时间须小于等于结束时间 '));
				} else {
					return callback();
				}
			};
			var maxTimesCheck = (rule, value, callback) => {
				if (value > 28 || value < 1) {
					return callback(new Error('时间须在1号至28号之间'));
				} else {
					return callback();
				}
			};

			var initialAmountCheck = (rule, value, callback) => {
				if (value > 9999 || value < 1) {
					return callback(new Error('金额须在1元至9999元之间'));
				} else {
					return callback();
				}
			};


			return {
				quillOption: quillConfig,
				lookVisable: false,
				lookPicture: '',
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				upLoadFile: config.UPLOAD_ZIP,
				loading: false,
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				ruleForm: {
					MallName: '',
					MainColor: '#ff547b',
					ContactPhone: '',
					MallLogoUrl: '',
					MallButtomLogoUrl: '',
					ReceiveName: '',
					ReceivePhone: '',
					ReceiveAddress: '',
					AreaCode: '',
					Welcom: '',
					AddressArr: [],
					ReceiveProvinceId: 0,
					ReceiveCityId: 0,
					ReceiveAreaId: 0,
					ReceiveProvince: '',
					ReceiveCity: '',
					ReceiveArea: '',
					PayBy: 1,
					Pay498MchId: '',
					Pay498PublicKey: '',
					IsOpenDistributorToWapProductDetail: 0,
					IsOpenRecordToWapProductDetail: 0,
					PublicImgLocation: 0,
					ProductShufflingPublicImgUrl: '',
					IsOpenProductDetailPublicImg: false,
					ProductDetailPublicImgUrl: '',
					IsOpenProductShufflingPublicImg: false,
					IsDisplaySoldOut: false,
					IsOpenDistributorCommissionDeduct: false,
					IsAutoDistributorDemotion: false,
					UserAgreementTitle: '',
					UserAgreementContent: '',

					WaitPayOrderCancelTimeShow: 20,
					AutomaticReceivingTimeToSecondShow: 7,
					IsOpenAutoConsentSupportRequest: 1,
					AutoConsentSupportRequestTimeShow: 7,
					IsOpenMallAutomaticReceiving: 1,
					aciveType: 1,
					MallAutomaticReceivingTimeShow: 7,
					WxPayMallNo: '',
					WapProductDetailStockDisplayType: 1,
					WapProductDetailStockDisplayMax: "",
					WapProductDetailShopInfoDisplayType: 1,
					cashMethods: 0,
					IsOpenWithdrawalsToAlipay: true,
					IsOpenWithdrawalsToBankCard: false,
					WithdrawalsStartDateEveryMonth: '',
					WithdrawalsEndDateEveryMonth: '',
					WithdrawalsDateIsLimit: 0,
					WithdrawalsMinMoney: 1,
					WithdrawalsExplain: '',
					ShareIconImgUrl: '',
					WxRefundCertImgUrl: '',
				},
				currentCount: '',
				rules: {
					MallName: [{
						required: true,
						message: '请输入商城名称',
						trigger: 'blur'
					}],
					ContactPhone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}],
					MallLogoUrl: [{
						required: true,
						message: '请选择商城LOGO',
						trigger: 'change'
					}],
					MallButtomLogoUrl: [{
						required: true,
						message: '请选择商城底部LOGO',
						trigger: 'change'
					}],
					ReceiveName: [{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}],
					ReceivePhone: [{
						required: true,
						message: '请填写收货人手机号码',
						trigger: 'blur'
					}],
					ReceiveAddress: [{
						required: true,
						message: '请填写详细收货地址',
						trigger: 'blur'
					}],
					AddressArr: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur'
					}],
					WaitPayOrderCancelTimeShow: [{
						required: true,
						validator: OrderAutoCancelToMinutesCheck,
						trigger: 'blur'
					}],
					AutomaticReceivingTimeToSecondShow: [{
						required: true,
						validator: receivetimeCheck,
						trigger: 'blur'
					}],
					AutoConsentSupportRequestTimeShow: [{
						required: true,
						validator: agreenTimeCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					MallAutomaticReceivingTimeShow: [{
						required: true,
						validator: aciveTimeCheck,
						trigger: 'blur'
					}],
					WapProductDetailStockDisplayMax: [{
						required: true,
						validator: stockNumberCheck,
						trigger: 'blur'
					}],
					cashMethods: [{
						required: true,
						message: '至少选择一种账户类型',
						trigger: 'change'
					}],
					IsOpenWithdrawalsToAlipay: [{
						required: true,
						validator: withdrawPayCheck,
						trigger: 'change'
					}],
					WithdrawalsStartDateEveryMonth: [{
						required: true,
						validator: minTimesCheck,
						trigger: 'blur'
					}],
					WithdrawalsEndDateEveryMonth: [{
						required: true,
						validator: maxTimesCheck,
						trigger: 'blur'
					}],
					WithdrawalsMinMoney: [{
						required: true,
						validator: initialAmountCheck,
						trigger: 'blur'
					}],
					IsOpenMallAutomaticReceiving: [{
						required: true,
						message: '是否开启商家自动确认时间',
						trigger: 'change'
					}],
				},
				dataList: [],

				isShowmasking: false

			};
		},

		beforeMount() {
			this.getDataList();
			this.getData()

		},
		mounted() {
			this.$refs.myTextEditor.quill.enable(false);
		},
		// updated() {
		// 	window.scroll(0, 0);
		// },

		methods: {
			handleChangePayBy() {
				this.ruleForm.WxRefundCertImgUrl = ''
				this.ruleForm.WxPayMallNo = ''
				this.ruleForm.Pay498MchId = ''
				this.ruleForm.Pay498PublicKey = ''
			},
			// 编辑时初始数据
			async getData() {
				try {
					let data = {}
					let res = await mallbaseinfo(data)
					this.ruleForm = res.Result
					this.ruleForm.MainColor = res.Result.MainColor ? res.Result.MainColor : '#ff547b';
					this.ruleForm.IsOpenAutoConsentSupportRequest = this.ruleForm.IsOpenAutoConsentSupportRequest ? 1 : 0
					this.ruleForm.IsOpenMallAutomaticReceiving = this.ruleForm.IsOpenMallAutomaticReceiving ? 1 : 0
					this.ruleForm.WithdrawalsDateIsLimit = this.ruleForm.WithdrawalsDateIsLimit ? 1 : 0
					this.ruleForm.AddressArr = [res.Result.ReceiveProvinceId + '', res.Result.ReceiveCityId + '', res.Result.ReceiveAreaId +
						''
					];
					this.ruleForm.PayBy = res.Result.PayBy
					this.ruleForm.Pay498MchId = res.Result.Pay498MchId
					this.ruleForm.Pay498PublicKey = res.Result.Pay498PublicKey
					this.ruleForm.IsOpenDistributorToWapProductDetail = res.Result.IsOpenDistributorToWapProductDetail ? 1 : 0
					this.ruleForm.ShareIconImgUrl = this.ruleForm.ShareIconImgUrl ? this.ruleForm.ShareIconImgUrl :
						'/image/20200424-600a0e17-9cf1-4e93-927f-4ef194c565be.png'

					this.ruleForm.IsOpenRecordToWapProductDetail = res.Result.IsOpenRecordToWapProductDetail ? 1 : 0
					window.localStorage.setItem('IsOpenWithdrawalsToAlipay', this.ruleForm.IsOpenWithdrawalsToAlipay);
					window.localStorage.setItem('IsOpenWithdrawalsToBankCard', this.ruleForm.IsOpenWithdrawalsToBankCard);
					//富文本编辑器神坑处理
					// this.$nextTick(function() {
					// 	this.$refs.myTextEditor.quill.enable(true);
					// 	this.$refs.myTextEditor.quill.blur();
					// });

					// this.ruleForm.shopName = res.Result.MallName;
					// this.ruleForm.areaCode = res.Result.AreaCode;
					// this.ruleForm.shopPhone = res.Result.ContactPhone;
					// this.ruleForm.mallLogoUrl = res.Result.MallLogoUrl;
					// this.ruleForm.mallFooterLogoUrl = res.Result.MallButtomLogoUrl;
					// this.ruleForm.receiverName = res.Result.ReceiveName;

					// this.ruleForm.receiverPhone = res.Result.ReceivePhone;
					// this.ruleForm.receiveProvinceId = res.Result.ReceiveProvinceId;
					// this.ruleForm.receiveCityId = res.Result.ReceiveCityId;

					// this.ruleForm.receiveAreaId = res.Result.ReceiveAreaId;
					// this.ruleForm.receiveProvince = res.Result.ReceiveProvince;
					// this.ruleForm.receiveCity = res.Result.ReceiveCity;
					// this.ruleForm.receiveArea = res.Result.ReceiveArea;

					// this.ruleForm.receiverAddressDetail = res.Result.ReceiveAddress;
					// this.ruleForm.welcomeRemark = res.Result.Welcom;					
					// this.ruleForm.PublicImgLocation = res.Result.PublicImgLocation;
					// this.ruleForm.ProductShufflingPublicImgUrl = res.Result.ProductShufflingPublicImgUrl;
					// this.ruleForm.IsOpenProductDetailPublicImg = res.Result.IsOpenProductDetailPublicImg;
					// this.ruleForm.ProductDetailPublicImgUrl = res.Result.ProductDetailPublicImgUrl;
					// this.ruleForm.IsOpenProductShufflingPublicImg = res.Result.IsOpenProductShufflingPublicImg;
					// this.ruleForm.IsDisplaySoldOut = res.Result.IsDisplaySoldOut;
					// this.ruleForm.IsOpenDistributorCommissionDeduct = res.Result.IsOpenDistributorCommissionDeduct;
					// this.ruleForm.UserAgreementTitle = res.Result.UserAgreementTitle;
					// this.ruleForm.UserAgreementContent = res.Result.UserAgreementContent;


				} catch (e) {
					//TODO handle the exception
				}finally{
					this.$nextTick(function() {
						if (this.$refs.myTextEditor.quill) {
							this.$refs.myTextEditor.quill.enable(true);
							this.$refs.myTextEditor.quill.blur();
							window.scrollTo(0, 0);
						}
					
					});
				}
			},
			async saveMallInfo() {
				try {
					// let data = {
					// 	MallInfo: {
					// 		MallName: this.ruleForm.shopName,
					// 		MainColor: this.ruleForm.MainColor,
					// 		AreaCode: this.ruleForm.areaCode,
					// 		ContactPhone: this.ruleForm.shopPhone,
					// 		MallLogoUrl: this.ruleForm.mallLogoUrl,
					// 		MallButtomLogoUrl: this.ruleForm.mallFooterLogoUrl,
					// 		ReceiveName: this.ruleForm.receiverName,
					// 		ReceivePhone: this.ruleForm.receiverPhone,
					// 		ReceiveProvinceId: this.ruleForm.receiveProvinceId,
					// 		ReceiveCityId: this.ruleForm.receiveCityId,
					// 		ReceiveAreaId: this.ruleForm.receiveAreaId,
					// 		ReceiveProvince: this.ruleForm.receiveProvince,
					// 		ReceiveCity: this.ruleForm.receiveCity,
					// 		ReceiveArea: this.ruleForm.receiveArea,
					// 		ReceiveAddress: this.ruleForm.receiverAddressDetail,
					// 		Welcom: this.ruleForm.welcomeRemark,
					// 		IsOpenDistributorToWapProductDetail: this.ruleForm.radioenter,
					// 		IsOpenRecordToWapProductDetail: this.ruleForm.IsOpenRecordToWapProductDetail,
					// 		PublicImgLocation: this.ruleForm.PublicImgLocation,
					// 		ProductShufflingPublicImgUrl: this.ruleForm.ProductShufflingPublicImgUrl,
					// 		IsOpenProductDetailPublicImg: this.ruleForm.IsOpenProductDetailPublicImg,
					// 		ProductDetailPublicImgUrl: this.ruleForm.ProductDetailPublicImgUrl,
					// 		IsOpenProductShufflingPublicImg: this.ruleForm.IsOpenProductShufflingPublicImg,
					// 		IsDisplaySoldOut: this.ruleForm.IsDisplaySoldOut,
					// 		IsOpenDistributorCommissionDeduct: this.ruleForm.IsOpenDistributorCommissionDeduct,
					// 		UserAgreementTitle: this.ruleForm.UserAgreementTitle,
					// 		UserAgreementContent: this.ruleForm.UserAgreementContent
					// 	}
					// };
					localStorage.setItem('IsOpenWithdrawalsToAlipay', this.ruleForm.IsOpenWithdrawalsToAlipay);
					localStorage.setItem('IsOpenWithdrawalsToBankCard', this.ruleForm.IsOpenWithdrawalsToBankCard);
					let data = {
						MallInfo: this.ruleForm
					};
					let res = await savemallbaseinfo(data);
					if (res.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: res.Message
						});
						this.getData()
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 查看示例
			lookShow(type) {
				if (type == 1) {
					this.lookPicture = 'https://cdn.dkycn.cn/melyshop/image/20200309-c3c2dea4-78ad-4a6b-9679-90ed834545c4.png'
				} else if (type == 2) {
					this.lookPicture = 'https://cdn.dkycn.cn/melyshop/image/20200309-baadf905-6483-4e9e-9ecc-2dd62188be92.png'
				} else {
					this.lookPicture = 'https://cdn.dkycn.cn/melyshop/image/20200319-5be96ed9-e606-4983-b9b9-f34a0a556cda.png'
				}
				this.lookVisable = true
			},
			//获取地址数据
			getDataList() {
				var that = this;
				axios
					.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.dataList = response.data;
					})
					.catch(function(error) {});
			},
			handleAddressChange() {
				this.ruleForm.ReceiveProvinceId = this.ruleForm.AddressArr[0];
				this.ruleForm.ReceiveCityId = this.ruleForm.AddressArr[1];
				this.ruleForm.ReceiveAreaId = this.ruleForm.AddressArr[2];
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ReceiveProvinceId) {
						this.ruleForm.ReceiveProvince = item.name;
						item.child.map(items => {
							if (items.id == this.ruleForm.ReceiveCityId) {
								this.ruleForm.ReceiveCity = items.name;
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.ReceiveAreaId) {
										this.ruleForm.ReceiveArea = lastitems.name;
									}
								});
							}
						});
					}
				});
			},
			handleRemove(file, fileList) {},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			// 保存
			saveSupiler(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.saveMallInfo()
					} else {
						return false;
					}
				});
			},
			handleChange(value) {},
			handleAvatarSuccess(res, file) {
				// const isLt50k = file.size / 1024 < 50;
				// if (!isLt50k) {
				// 	alert('上传图片大小不能超过50kB哦!');
				// 	return false;
				// }
				this.ruleForm.MallLogoUrl = res.data[0];
				this.$forceUpdate();
			},
			handleAvatarSuccessBook(res, file) {
				this.ruleForm.WxRefundCertImgUrl = res.data[0]
			},
			shareIconSuccess(res, file) {
				this.ruleForm.ShareIconImgUrl = res.data[0]
			},
			beforeAvatarUploadshare(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '分享图标请控制在1M以内!'
					})
				}
				return isLt1M
			},
			handleFooterAvatarSuccess(res, file) {
				// const isLt50k = file.size / 1024 < 150;
				// if (!isLt50k) {
				// 	alert('上传图片大小不能超过50kB哦!');
				// 	return false;
				// }
				this.ruleForm.MallButtomLogoUrl = res.data[0];
				this.$forceUpdate();
			},

			// 选择在商品轮播公共图/商品详情公共图
			handleCheckShufflingPublic(e) {
				this.ruleForm.IsOpenProductShufflingPublicImg = e
				if (!this.ruleForm.IsOpenProductShufflingPublicImg) {
					this.ruleForm.ProductShufflingPublicImgUrl = ''
					this.ruleForm.PublicImgLocation = 0
				}
			},
			// 选择在商品轮播公共图/商品详情公共图
			handleCheckDetailPublic(e) {
				this.ruleForm.IsOpenProductDetailPublicImg = e
				if (!this.ruleForm.IsOpenProductDetailPublicImg) {
					this.ruleForm.ProductDetailPublicImgUrl = ''
				}
			},
			handleGoodsCarouselSuccess(res, file) {
				const isLt2M = file.size / 1024 < 2048;
				if (!isLt2M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '图片大小请控制在2M以内!'
					})
					return false;
				}
				this.ruleForm.ProductShufflingPublicImgUrl = res.data[0];
				this.$forceUpdate();
			},
			handleGoodsDetaileSuccess(res, file) {
				const isLt2M = file.size / 1024 < 2048;
				if (!isLt2M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '图片大小请控制在2M以内!'
					})
					return false;
				}
				this.ruleForm.ProductDetailPublicImgUrl = res.data[0];
				this.$forceUpdate();
			}
		}
	};
</script>

<style lang="less" scoped>
	.container {
		// padding: 10px;
		width: 100%;
		overflow: hidden;

		/deep/.el-input {
			width: 300px;
		}

		/deep/.el-textarea {
			width: 500px;
		}
	}

	.headIconImg {
		position: relative;

		.iconImgmasking {
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.5);
			width: 145px;
			height: 145px;
			z-index: 2;
		}

		.iconimgBox {
			position: absolute;
			top: 0;
			left: 0;
			width: 145px;
			height: 145px;
			text-align: center;
			line-height: 145px;
			z-index: 3;
			color: #fff;
		}

	}

	.payMoneys-with {
		.filter-container .filter-item {
			margin-bottom: 0;
			margin-right: 0
		}
	}


	.upload-avatar {
		width: 145px;
		height: 145px;
		object-fit: contain;
	}

	.footer {
		background-color: #fff;
		width: 100%;
		margin-right: 30px;
		height: 80px;
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
		z-index: 10;
	}

	/deep/.el-color-picker--medium .el-color-picker__trigger {
		width: 145px !important;
	}

	.other-card {
		/deep/.el-form-item__label {
			width: 260px !important;
		}

		/deep/.el-form-item__content {
			margin-left: 260px !important;
		}
	}
</style>
